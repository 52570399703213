.loading-spinner {
    display: flex;
    margin-top: 3em;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.loading-spinner::after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}