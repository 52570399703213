.search-tool {
    display: inline-block;
    position: relative;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .search-form {
        position: relative;



        input {
            padding: 0.5rem 1rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 1rem;
            transition: border-color 0.3s ease;

            &:focus {
                outline: none;
                border-color: #007bff;
                box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
            }

        }

        .search-icon {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            color: #aaa;
        }

    }

    .query-list {

        color: white;

        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;

        h4 {
            font-weight: 500;
            padding: 0;
            margin: 0;
        }

    }
}